<template>
  <a-row class="content-fix" type="flex">
    <spinner v-if="loading" />

    <a-col v-show="!loading" class="height100 flex-1">
      <span slot="tab"> <a-icon type="project" />Содержимое</span>

      <!-- lang tabs -->
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Содержимое
        </button>

        <button
          class="tab-btn"
          :class="{ active: tab === 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />Фото
        </button>
      </a-row>

      <div v-show="tab === 'content'">
        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="3" class="form_title"> Язык: </a-col>

          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
              </a-button>
            </a-row>
          </a-col>
        </a-row>

        <!-- title -->
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="3" class="form_title">
            <label>Заголовок:</label>
          </a-col>
          <a-col :span="18" class="sm-mb">
            <a-input
              v-model="form.title[langTab]"
              class="form_title-input"
              placeholder="Заголовок"
            />
            <div v-if="isTitleNull" class="error-text">
              Введите заголовок на одном из языков.
            </div>
          </a-col>
        </a-row>

        <a-row :class="{ 'form-group--error': emptyShortContent }">
          <a-col :span="3" class="form_title">
            <label>Краткое содержание:</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-textarea
              v-model="form.short_content[langTab]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              class="font-16"
            />
            <div v-if="isShortContentNull" class="error-text">Заполните</div>
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline">
          <a-col :span="3" class="form_title"> URL: </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.url"
              placeholder="Введите url (для внешних ссылок)"
            />
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline">
          <a-col :span="3" class="form_title"> Веб-сайт URL: </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.website"
              placeholder="Введите веб-сайт URL"
            />
          </a-col>
        </a-row>

        <a-row
          :class="{ 'form-group--error': emptyPhone }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="3" class="form_title">
            <label>Телефон номер:</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.phone[langTab]"
              placeholder="Введите номер телефона"
              name="phone"
            />
            <!--<a-input
              v-model="form.phone"
              v-mask="'+### (##) ###-##-##'"
              placeholder="Введите номер телефона"
              name="phone"
              type="tel"
            />
            -->
            <div v-if="isPhoneNull" class="error-text">
              Введите номер телефона.
            </div>
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline">
          <a-col :span="3" class="form_title">
            <label>Адрес:</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.address[langTab]"
              placeholder="Введите адрес"
            />
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline">
          <a-col :span="3" class="form_title">
            <label>Директор:</label>
          </a-col>
          <a-col
            :class="{ 'form-group--error': emptyLeader }"
            :span="12"
            class="sm-mb"
          >
            <a-select
              v-model="form.leadership"
              :filter-option="filterOption"
              option-filter-prop="children"
              placeholder="Директор"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="staff in staffList"
                :key="staff.id"
                :value="staff.id"
              >
                ({{ staff.id }}) {{ staff.full_name || "-" }}
              </a-select-option>
            </a-select>
            <div v-if="isLeadershipNull" class="error-text">
              Выберите директора
            </div>
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="3" class="form_title">
            Регулирование задач и функций:
          </a-col>
          <a-col :span="12">
            <a-button
              v-if="document_files_array.length === 0"
              type="primary"
              @click="$refs.documentRef.click()"
            >
              <input
                ref="documentRef"
                accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                hidden
                type="file"
                @change="handleDocumentInput"
              />
              <a-icon type="upload" />Выберите файл
            </a-button>

            <template v-if="document_files_array.length !== 0">
              <p style="padding-left: 15px">Файлы для загрузки</p>
              <InputFileItem
                v-for="item in document_files_array"
                :key="item.uid"
                :file="item.binary_file"
                :inputcopy="false"
                class="mt-2 no-a-hover"
                @delete="deleteDocumentFileFromState(item)"
              />

              <a-row
                style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
              />
            </template>
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="3" class="form_title"> Рабочий план: </a-col>
          <a-col :span="12">
            <a-button
              v-if="work_plan_files_array.length === 0"
              type="primary"
              @click="$refs.workDocumentRef.click()"
            >
              <input
                ref="workDocumentRef"
                accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                hidden
                type="file"
                @change="handleWorkDocumentInput"
              />
              <a-icon type="upload" />Выберите файл
            </a-button>

            <template v-if="work_plan_files_array.length !== 0">
              <p style="padding-left: 15px">Файлы для загрузки</p>
              <InputFileItem
                v-for="item in work_plan_files_array"
                :key="item.uid"
                :file="item.binary_file"
                :inputcopy="false"
                class="mt-2 no-a-hover"
                @delete="deleteWorkDocumentFileFromState(item)"
              />

              <a-row
                style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
              />
            </template>
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="3" class="form_title">
            Организационная структура:
          </a-col>
          <a-col :span="12">
            <a-button
              v-if="structure_files_array.length === 0"
              type="primary"
              @click="$refs.structureDocumentRef.click()"
            >
              <input
                ref="structureDocumentRef"
                accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                hidden
                type="file"
                @change="handleStructureDocumentInput"
              />
              <a-icon type="upload" />Выберите файл
            </a-button>

            <template v-if="structure_files_array.length !== 0">
              <p style="padding-left: 15px">Файлы для загрузки</p>
              <InputFileItem
                v-for="item in structure_files_array"
                :key="item.uid"
                :file="item.binary_file"
                :inputcopy="false"
                class="mt-2 no-a-hover"
                @delete="deleteStructureDocumentFileFromState(item)"
              />

              <a-row
                style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
              />
            </template>
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline">
          <a-col :span="3" class="form_title"> Позиция: </a-col>
          <a-col :span="3" class="sm-mb">
            <a-input
              v-model="form.level"
              type="number"
              name="position"
              placeholder="Введите позицию"
            />
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="3" class="form_title"> Статус: </a-col>
          <a-switch v-model="form.is_active" />
        </a-row>

        <a-row class="my-1">
          <a-col :span="3" class="form_title"> На главную: </a-col>
          <a-switch v-model="form.on_slider" />
        </a-row>

        <a-row class="my-1">
          <a-col :span="3" class="form_title"> На блок: </a-col>
          <a-switch v-model="form.is_blog" />
        </a-row>

        <!-- editor -->
        <a-row
          :class="{ 'form-group--error': emptyContent }"
          style="margin-top: 25px"
          type="flex"
        >
          <a-col :span="3" class="form_title"> {{ $t("Content") }}: </a-col>

          <a-col v-show="langTab === 'oz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.oz"
              :initial-value="form.content.oz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.oz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>

          <a-col v-show="langTab === 'uz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.uz"
              :initial-value="form.content.uz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.uz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>

          <a-col v-show="langTab === 'ru'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.ru"
              :initial-value="form.content.ru"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.ru = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>

          <a-col v-show="langTab === 'qr'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.qr"
              :initial-value="form.content.qr"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.qr = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>

          <a-col v-show="langTab === 'en'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.en"
              :initial-value="form.content.en"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.en = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
        </a-row>

        <a-row
          class="form-buttons__action"
          style="margin-top: 20px"
          type="flex"
        >
          <a-col :span="3" class="form_title" />
          <a-col>
            <a-button :disabled="pending" @click="$router.go(-1)">
              <a-icon type="close" />{{ $t("Cancel") }}
            </a-button>

            <a-button
              :loading="pending"
              style="margin-left: 10px"
              type="primary"
              @click="updateActivity((saveback = false))"
            >
              <a-icon type="check" />
              {{ $t("Save") }}
            </a-button>

            <a-button
              :loading="pending"
              style="margin-left: 10px"
              type="primary"
              @click="updateActivity((saveback = true))"
            >
              <a-icon type="check" />
              {{ $t("SaveAndExit") }}
            </a-button>
          </a-col>
        </a-row>
      </div>

      <div v-show="tab === 'photo'">
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="3" class="form_title">
            <label>Фото:</label>
          </a-col>

          <a-col :span="12" class="sm-mb">
            <div class="photo_field">
              <div class="activity_image">
                <img :src="photo" alt="no photo" />
              </div>
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <span v-if="!photo" style="padding-left: 8px">
                Картинка не выбрана
              </span>
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFile"
              />
            </div>
          </a-col>
        </a-row>
      </div>
    </a-col>

    <!-- store image modal -->
    <a-modal
      :visible="modalVisible"
      title="Хранилище изображений"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="80%"
    >
      <imageStorage
        @pasteHandler="editorInstance.insertContent(arguments[0])"
      />
      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> Закрыть </a-button>
      </template>
    </a-modal>

    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import spoiler from "@/mixins/spoiler.js"
import InputFileItem from "@/components/utils/input-file-item.vue"
const newsDefaultFields = () => ({
  title: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  content: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  short_content: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  // phone: "",
  phone: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  address: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  url: "",
  website: "",
  photo: null,
  file: null,
  work_plan_file: null,
  structure_file: null,
  leadership: null,
  type: null,
  is_active: false,
  on_slider: false,
  is_blog: false
})

export default {
  name: "HeadquartersUpdate",
  components: {
    InputFileItem,
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler"),
    imageStorage: () => import("@/components/imageStorage/imageStorage"),
    editor: () => import("@tinymce/tinymce-vue")
  },
  mixins: [spoiler],
  data() {
    return {
      tab: "content",
      langTab: $langPrefix,
      form: { ...newsDefaultFields() },
      pending: false,
      tabPane: "content",
      photo: null,
      loading: false,
      emptyTitle: false,
      emptyContent: false,
      emptyShortContent: false,
      emptyPhone: false,
      emptyPhoto: false,
      emptyLeader: false,
      modalVisible: false,
      staffList: [],
      document_files_array: [],
      work_plan_files_array: [],
      structure_files_array: []
    }
  },
  computed: {
    isTitleNull() {
      return !!(
        !this.form.title.uz &&
        !this.form.title.oz &&
        !this.form.title.ru &&
        !this.form.title.en
      )
    },
    isLeadershipNull() {
      return !this.form.leadership
    },
    isContentNull() {
      return !!(
        !this.form.content.uz &&
        !this.form.content.oz &&
        !this.form.content.ru &&
        !this.form.content.en
      )
    },
    isPhoneNull() {
      return !this.form.phone
    },
    isPhotoNull() {
      return !this.photo
    },
    isShortContentNull() {
      return !!(
        !this.form.short_content.uz &&
        !this.form.short_content.oz &&
        !this.form.short_content.ru &&
        !this.form.short_content.en
      )
    },
    getKey() {
      return this.langTab
    }
  },
  watch: {
    $route: {
      handler() {
        this.fetchData()
      },
      immediate: true
    }
  },
  mounted() {
    this.fetchLeadership()
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    tabPaneChangeHandler(arg) {
      this.tabPane = arg
    },
    check2Null(arg) {
      return Object.values(arg).every((item) => !item)
    },
    validateForm() {
      if (this.check2Null(this.form.title)) {
        this.emptyTitle = true
        this.$message.error("Введите заголовок на одном из языков")
        return false
      }
      if (this.isLeadershipNull) {
        this.emptyLeader = true
        this.$message.error("Выберите директора")
        return false
      }
      if (this.isPhoneNull) {
        this.emptyPhone = true
        this.$message.error("Введите номер телефона")
        return false
      }
      if (this.isPhotoNull) {
        this.emptyPhoto = true
        this.$message.error("Добавтье фотографию")
        return false
      }
      /*if (this.check2Null(this.form.short_content)) {
        this.emptyShortContent = true
        this.$message.error("Введите Краткое содержание на одном из языков")
        return false
      }
      if (this.check2Null(this.form.content)) {
        this.emptyContent = true
        this.$message.error("Введите контент на одном из языков")
        return false
      }*/
      return true
    },
    openStoreModal() {
      this.modalVisible = true
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }

        if (this.form.content[from]) {
          this.form.content[to] = this[methodName](this.form.content[from])
        }
        if (this.form.short_content[from]) {
          this.form.short_content[to] = this[methodName](
            this.form.short_content[from]
          )
        }
        this.form.language_support[to] = true
      } catch (error) {
        this.$sentry.captureMessage(error)
      }
    },
    async fetchLeadership() {
      try {
        let res = await this.$api.get("/admin/structure/leadership/list/", {
          params: {
            type: 1
          }
        })
        if (res.status === 200) {
          this.staffList = res && res.data
        }
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }
    },
    fetchData() {
      this.loading = true
      if (isNaN(this.$route.params.id)) {
        this.loading = false
        return
      } 

      this.$api
        .get("/admin/common/activity/" + this.$route.params.id + "/update/")
        .then((res) => {
          if (res && res.data) {
            const { ...data } = res.data
            this.form = {
              ...this.form,
              ...data,
              title: {
                ru: res.data.title_ru,
                qr: res.data.title_qr,
                en: res.data.title_en,
                oz: res.data.title_oz,
                uz: res.data.title_uz
              },
              content: {
                ru: res.data.content_ru,
                en: res.data.content_en,
                oz: res.data.content_oz,
                uz: res.data.content_uz,
                qr: res.data.content_qr
              },
              short_content: {
                ru: res.data.short_content_ru,
                en: res.data.short_content_en,
                oz: res.data.short_content_oz,
                uz: res.data.short_content_uz,
                qr: res.data.short_content_qr
              },
              phone: {
                ru: res.data.phone_ru,
                en: res.data.phone_en,
                oz: res.data.phone_oz,
                uz: res.data.phone_uz,
                qr: res.data.phone_qr
              },
              address: {
                ru: res.data.address_ru,
                en: res.data.address_en,
                oz: res.data.address_oz,
                uz: res.data.address_uz,
                qr: res.data.address_qr
              }
            }
          }

          if (this.form.file) {
            let file = this.form.file
            this.document_files_array.push({
              binary_file: {
                file: file,
                name: file.split("/").pop()
              }
            })
            // console.log(this.document_files_array)
          }

          if (this.form.work_plan_file) {
            let file = this.form.work_plan_file
            this.work_plan_files_array.push({
              binary_file: {
                file: file,
                name: file.split("/").pop()
              }
            })
            // console.log(this.work_plan_file)
          }

          if (this.form.structure_file) {
            let file = this.form.structure_file
            this.structure_files_array.push({
              binary_file: {
                file: file,
                name: file.split("/").pop()
              }
            })
            // console.log(this.structure_file)
          }

          this.photo = this.form.photo
          this.loading = false
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    },
    async updateActivity(saveback) {
      if (!this.validateForm()) return
      if (this.pending) return
      this.pending = true

      try {
        let newData = cloneDeep(this.form)
        // console.log(newData)
        // console.log(this.form)
        newData = {
          ...newData,
          title_ru: newData.title.ru,
          title_uz: newData.title.uz,
          title_oz: newData.title.oz,
          title_en: newData.title.en,
          title_qr: newData.title.qr,
          content_ru: newData.content.ru,
          content_uz: newData.content.uz,
          content_oz: newData.content.oz,
          content_en: newData.content.en,
          content_qr: newData.content.qr,
          short_content_ru: newData.short_content.ru,
          short_content_uz: newData.short_content.uz,
          short_content_oz: newData.short_content.oz,
          short_content_en: newData.short_content.en,
          short_content_qr: newData.short_content.qr,
          phone_ru: newData.phone.ru,
          phone_uz: newData.phone.uz,
          phone_oz: newData.phone.oz,
          phone_en: newData.phone.en,
          phone_qr: newData.phone.qr,
          address_ru: newData.address.ru,
          address_uz: newData.address.uz,
          address_oz: newData.address.oz,
          address_en: newData.address.en,
          address_qr: newData.address.qr
        }
        delete newData.title
        delete newData.content
        delete newData.short_content
        delete newData.phone
        delete newData.address
        delete newData.file
        delete newData.work_plan_file
        delete newData.structure_file

        if (typeof newData.photo === "string") {
          delete newData.photo
        }

        let f = new FormData()
        Object.keys(newData).forEach((key) => {
          if (newData[key] === null) {
            newData[key] = ""
          }
          f.append(key, newData[key])
          /*newData[key] && f.append(key, newData[key])
          key === "is_active" && f.append(key, newData[key])
          key === "is_blog" && f.append(key, newData[key])
          key === "on_slider" && f.append(key, newData[key])
          key === "type" && f.append(key, this.$route.params.type)*/
        })

        if (this.document_files_array[0] && this.document_files_array[0].uid) {
          this.document_files_array.forEach((item) => {
            item &&
              item.binary_file &&
              !item.binary_file.id &&
              f.append("file", item.binary_file, item.binary_file.name)
          })
        } else if (this.document_files_array.length === 0) {
          f.append("file", "")
        }

        if (
          this.work_plan_files_array[0] &&
          this.work_plan_files_array[0].uid
        ) {
          this.work_plan_files_array.forEach((item) => {
            item &&
              item.binary_file &&
              !item.binary_file.id &&
              f.append(
                "work_plan_file",
                item.binary_file,
                item.binary_file.name
              )
          })
        } else if (this.work_plan_files_array.length === 0) {
          f.append("work_plan_file", "")
        }

        if (
          this.structure_files_array[0] &&
          this.structure_files_array[0].uid
        ) {
          this.structure_files_array.forEach((item) => {
            item &&
              item.binary_file &&
              !item.binary_file.id &&
              f.append(
                "structure_file",
                item.binary_file,
                item.binary_file.name
              )
          })
        } else if (this.structure_files_array.length === 0) {
          f.append("structure_file", "")
        }

        for (let pair of f.entries()) {
          console.log(pair[0] + "=" + pair[1])
        }

        try {
          let res = await this.$api.patch(
            "/admin/common/activity/" + this.$route.params.id + "/update/",
            f
          )
          if (res && res.status === 200) {
            if (saveback) {
              await this.$router.push({
                name: "departments",
                params: {
                  ...this.$route.params
                }
              })
            }
          } else {
            this.$message.error(JSON.stringify(res.response.data))
          }
          this.pending = false
          // this.$router.go(0)
        } catch (e) {
          console.error(e)
          this.$sentry.captureMessage(e)
        }
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }
    },
    handleFile(e) {
      this.photo = URL.createObjectURL(e.target.files[0])
      this.form.photo = e.target.files[0]
    },
    handleDocumentInput(e) {
      // console.log(e.target.files)
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.document_files_array = [
            ...this.document_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
      }
      console.log("document_files_array", this.document_files_array)
      this.$refs.documentRef.value = null
    },
    handleWorkDocumentInput(e) {
      // console.log(e.target.files)
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.work_plan_files_array = [
            ...this.work_plan_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
      }
      console.log("work_plan_files_array", this.work_plan_files_array)
      this.$refs.workDocumentRef.value = null
    },
    handleStructureDocumentInput(e) {
      // console.log(e.target.files)
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.structure_files_array = [
            ...this.structure_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
      }
      console.log("structure_files_array", this.structure_files_array)
      this.$refs.structureDocumentRef.value = null
    },
    deleteDocumentFileFromState(e) {
      this.document_files_array = this.document_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      // console.log(this.document_files_array, e)
    },
    deleteWorkDocumentFileFromState(e) {
      this.work_plan_files_array = this.work_plan_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      // console.log(this.work_plan_files_array, e)
    },
    deleteStructureDocumentFileFromState(e) {
      this.structure_files_array = this.structure_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      // console.log(this.structure_files_array, e)
    }
  }
}
</script>

<style>
.ant-modal {
  max-height: 600px;
}
.scroll-container {
  padding: 20px 0;
}
.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.activity_image {
  width: 200px;
  height: 200px;
  border: 1px solid grey;
  /* border-radius: 40px; */
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}
.photo_field img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.photo_field {
  margin: 10px 0;
  height: 200px;
  padding: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
</style>
